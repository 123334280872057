import { SET_LOGGED_IN, LOGOUT, ADD_APPOINTMENT, REMOVE_APPOINTMENT, RESET_APPOINTMENTS, SET_USER_TOKEN, SET_USER_ID, ADD_APPOINTMENT_DATA ,SET_SELECTED_LANG, SET_LANG_DATA} from "./types";

export const setSelectedLang = (data) => {
    return {
        type: SET_SELECTED_LANG,
        data: data
    }
}
export const setLangData = (data) => {
    return {
        type: SET_LANG_DATA,
        data: data
    }
}

export const setLoggedIn = (data) => {
  return {
    type: SET_LOGGED_IN,
    data: data
  }
}
export const setUserToken = (data) => {
  return {
    type: SET_USER_TOKEN,
    data: data
  }
}
export const setUserId = (data) => {
  return {
    type: SET_USER_ID,
    data: data
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  }
}


// export const setUserName = (data) => {
//     return {
//         type: SET_USER_NAME,
//         data: data
//     }
// }

export const addAppointment = (appointmentData) => {
  return {
    type: ADD_APPOINTMENT,
    payload: appointmentData,
  };
};

// export const addAppointmentData = (userDataTime) => {
//   return {
//     type: ADD_APPOINTMENT_DATA,
//     payload: userDataTime,
//   };
// };

export const addAppointmentData = (userDataTime) => {
  return {
    type: ADD_APPOINTMENT_DATA,
    payload: userDataTime,
  };
};


export const removeAppointment = (salonId) => {
  return {
    type: REMOVE_APPOINTMENT,
    payload: salonId,
  };
};

export const resetAppointments = () => {
  return {
    type: RESET_APPOINTMENTS
  }
}
