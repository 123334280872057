import React, { useState, useEffect } from "react";
import "./salon.scss";
import { FaStar } from 'react-icons/fa'
import { BsFillCartCheckFill } from 'react-icons/bs'
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { ChooseButton } from "../../components/ui/buttonGroup";
import { connect, useDispatch } from "react-redux";
import Slideshow from "../../components/Slider/slideShow";
import { addAppointment, resetAppointments } from "../../redux/Functions/actions";
import { CiGrid41 } from 'react-icons/ci'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Rating } from "@mui/material"
import GoogleMap from "../../components/Map/GoogleMap";
import Checkbox from "@mui/material/Checkbox";
import { RxDotFilled, RxArrowRight, RxReload } from 'react-icons/rx'
import axios from "../../axios";
import axioss from "axios";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const SalonPage = (props, { addAppointment }) => {


  const location = useLocation()
  const [salon_id, setSalon_id] = useState(null)

  const [rating, setRating] = useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [salon, setSalon] = useState([])
  const [salonWorkingHours, setSalonWorkingHours] = useState([])
  const handleRatingChange = (value) => {
    setRating(value);
  };
  const handleCheckboxChange = (value) => {
    setSelectedCheckbox(value);
  };
  const dispatch = useDispatch();
  // const salon = Data.find((salon) => salon?.id === id);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [appointmentData, setAppointmentData] = useState(null);
  const [isBtnActive, setIsBtnActive] = useState(false);

  const navigate = useNavigate()

  // const handleClick = (treatment) => {
  //   const { id: salonId, name: salonName } = salon;
  //   const { name: treatmentName, time: timeToFinish } = treatment;

  //   const appointmentData = {
  //     salonId,
  //     salonName,
  //     treatmentName,
  //     timeToFinish,
  //   };

  //   dispatch(addAppointment(appointmentData));
  // };

  //   const appointmentData = {
  //     salonId,
  //     salonName,
  //     treatmentName,
  //     timeToFinish,
  //   };

  //    dispatch(resetAppointments()); 
  //      dispatch(addAppointment(appointmentData));
  // };

  const handleProceedToCheckoutBtn = () => {
    setIsBtnActive(!isBtnActive);
  };
  function TranslateLabel(key) {
    const langData = props.lang_data;
    const selected_lang = props.selected_lang
    if (selected_lang === 'AL') {
      let translate = langData?.filter(el => el.key === key)[0]?.label_al;
      return translate
    }
    else {
      let translate = langData?.filter(el => el.key === key)[0]?.label_en;
      return translate
    }


  }
  const handleChooseButton = (treatment) => {
    navigate("/checkout")
    props.addAppointment(treatment, salon.id);
    setAppointmentData({ treatment, salonId: salon.id });
  };

  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  // const [placeName, setPlaceName] = useState()
  const [salonWorkers, setSalonWorkers] = useState()


  const salonId = useParams("id");
  const { salonName, salonAddress } = useParams();
  const id = useParams();
  // console.log("salonAddress", salonAddress)

  const handeleGeetFromQuery = () => {
    if (salonName && salonAddress) {
      axios.get(`/api/client/salon_address/singleAddress/${salonName}/${String(salonAddress)}`)
        .then((res) => {
          // console.log("arton ramadani", res.data)
          setSalon_id(res?.data?.id)
          setLatitude(res?.data?.salon_address_data?.latitude)
          setLongitude(res?.data?.salon_address_data?.longitude)
          setSalon(res?.data)
          setSalonWorkingHours(res?.data?.salon_working_hours)
        })
        .catch(err => console.log('error', err))
    }
    else if (salonId?.id) {
      axios.get(`/api/client/salon_address/single/${salonId.id}`)
        .then((res) => {
          setLatitude(res?.data?.salon_address_data?.latitude)
          setLongitude(res?.data?.salon_address_data?.longitude)
          setSalon(res?.data)
          setSalonWorkingHours(res?.data?.salon_working_hours)
        })
        .catch(err => console.log('error', err))
    }
    else {

    }
  }

  const handleGetWorkers = () => {
    axios.get(`/api/client/users/salon_workers/${salonId.id}`)
      .then((res) => {
        setSalonWorkers(res.data)
        // setSalonWorkingHours(res.data.salon_working_hours)
      })
      .catch(err => console.log('error', err))
  }

  const [allServices, setAllServices] = useState()

  const handleGetAllServices = () => {
    axios.get(`/api/client/services/all_ser`)
      .then((res) => {
        setAllServices(res.data)

      })
      .catch(err => console.log('error', err))
  }
  const [salonSingleService, setSalonSingleService] = useState({
    service: [],
    subServices: [],
  });
  const [showServices, setShowServices] = useState(false)

  const [selectedTab, setSelectedTab] = useState(0);

  const handleGetSingleService = (serviceId) => {
    axios
      .get(`/api/client/salon_services/single_salon_services/${salon.id}/${serviceId}`)
      .then((res) => {
        const selectedService = allServices.find((service) => service.id === serviceId);
        setSalonSingleService({
          service: selectedService,
          subServices: res.data,
        });
        setShowServices(res.data.length !== 0);
      })
      .catch((err) => console.log('error', err));
  };


  // console.log("from", salon_id)

  function handleGetAllSalonServices(salon_id) {
    axios
      .get(`/api/client/salon_services/all_single_salon_services/${salon_id}`)
      .then((res) => {
        setSalonSingleService({
          service: [],
          subServices: res.data,
        });
        setShowServices(res.data.length === 0);
      })
      .catch((err) => console.log('error', err));
  }
  useEffect(() => {
    handleGetAllSalonServices(salon_id)
  }, [salon_id])
  // const handleGetSingleService = (serviceId) => {
  //   axios
  //     .get(`/api/client/salon_services/single_salon_services/${salonId.id}/${serviceId}`)
  //     .then((res) => {
  //       setSalonSingleService(res.data);
  //       setShowServices(res.data.length === 0);
  //     })
  //     .catch((err) => console.log('error', err));
  // };


  // useEffect(() => {
  //   handleGetWorkers();
  //   axioss.get(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAx2mpUulK8e5rn9PB09xmSDDccbCoVMps`
  //   ).then(response => {
  //     setPlaceName(response?.data?.results[1]?.formatted_address)
  //   }).catch(err => console.log(err))
  // }, [])



  const currentDate = new Date();
  const options = { weekday: 'long', };
  const currentDay = currentDate.toLocaleDateString(undefined, options).toLowerCase();
  const currentDayWorkingHours = salonWorkingHours?.find(el => el.day.toLowerCase() == currentDay)
  const originalStartTime = currentDayWorkingHours?.start_time;
  const originalEndTime = currentDayWorkingHours?.end_time;

  const opt = { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' }


  const start_time = new Date(originalStartTime).toLocaleTimeString([], opt);
  const end_time = new Date(originalEndTime).toLocaleTimeString([], opt);


  useEffect(() => {
    handeleGeetFromQuery();
    handleGetAllServices();
    dispatch(resetAppointments());
  }, [dispatch])



  // const getInitials = (name) => {
  //   const initials = name.charAt(0).toUpperCase();
  //   return initials;
  // };

  // const ratings = [5, 4, 3, 2, 1];
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideClick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const totalImages = salon?.salon_imgs?.length || 0;
    let loadedImages = 0;

    const handleImageLoad = () => {
      loadedImages++;
      if (loadedImages === totalImages) {
        setLoaded(true);
      }
    };

    setLoaded(false);
    loadedImages = 0;

    salon?.salon_imgs?.forEach((image) => {
      const img = new Image();
      img.src = `${process.env.REACT_APP_API}${image.img_url}`;
      img.onload = handleImageLoad;
    });
  }, [salon]);
  const dayOrder = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  // Custom sorting function to sort the array based on dayOrder
  const sortedHours = salon?.salon_working_hours?.sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);

  return (
    <div className="salon-layout">
      <div className="salon-inner">
        <div className="banner">
          <h1>{salon?.salon?.name_al}</h1>
          <div className="info">
            {/* NEXT FAZE */}
            {/* 
              <div className="rating">
                  <span>5.0{salon?.rating}</span>
                  {Array(4).fill(<span className="star-icon"><FaStar /></span>)}
              </div> 
            */}
            {/* NEXT FAZE */}

            <div className="inline">
              <p>{salon?.street_address_al}</p>
              {/* <button>Filtro</button> */}
            </div>
          </div>
          <div className="slider-wrapper">
            {/* <Slideshow /> */}
            <div className="slideshow-container">
              {/* <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)} >
                {salon?.salon_imgs?.map((image, index) => (
                  <div className="slider-img" onClick={() => handleSlideClick(index)} >
                    <img src={`${process.env.REACT_APP_API}${image.img_url}`} alt={`Slide ${index}`} />
                    <div className="slider-text">
                      <h5>{image?.img_alt}</h5>
                      <span>{index + 1} / {salon?.salon_imgs?.length}</span>
                    </div>
                  </div>
                  
                ))}
              </Slide> */}

              {/* {loaded ? (
                <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)}>
                  {salon?.salon_imgs?.map((image, index) => (
                    <div key={index} className="slider-img" onClick={() => handleSlideClick(index)}>
                      <img
                        src={`${process.env.REACT_APP_API}${image.img_url}`}
                        alt={`Slide ${index}`}
                      />
                      <div className="slider-text">
                        <h5>{image?.img_alt}</h5>
                        <span>{index + 1} / {salon?.salon_imgs?.length}</span>
                      </div>
                    </div>
                  ))}
                </Slide>
              ) : (
                <div className="loading-message">Loading...</div>
              )} */}

              {loaded ? (
                <Slide onChange={(currentIndex) => setActiveIndex(currentIndex)}>
                  {salon?.salon_imgs?.map((image, index) => (
                    <div className="slider-img" onClick={() => handleSlideClick(index)} key={index}>
                      <img
                        loading="lazy"
                        src={`${process.env.REACT_APP_API}${image.img_url}`}
                        alt={`Slide ${index}`}
                      />
                      <div className="slider-text">
                        <h5>{image?.img_alt}</h5>
                        <span>{index + 1} / {salon?.salon_imgs?.length}</span>
                      </div>
                    </div>
                  ))}
                </Slide>
              ) : (
                <div className="slider-img no-image-placeholder">
                  <img
                    loading="lazy"
                    src="https://cdn11.bigcommerce.com/s-m1jiibmpmc/stencil/080443d0-e8e7-0139-b5bb-5eca3f67671f/e/f51774b0-60cc-0138-751f-0242ac11000b/icons/icon-no-image.svg"
                    alt="No Image Available"
                  />
                  <div className="slider-text">
                    <h5>Nuk ka imazh të disponueshëm</h5>
                  </div>
                </div>
              )}


              <Lightbox
                plugins={[Captions]}
                open={open !== false}
                index={activeIndex}
                close={() => setOpen(false)}
                slides={salon?.salon_imgs?.map((image) => ({ src: `${process.env.REACT_APP_API}${image?.img_url}`, description: image?.img_alt }))}
              />
            </div>
          </div>



          <div className="location-schedule">

            <div className="address">
              <span>📍</span>
              <p>{salon?.street_address_al}</p>
            </div>

            <div className="schedule">
              <span>🕙</span>
              <p>
                {currentDayWorkingHours?.work_this_day === true ?

                  `Hapur sot: ${start_time} - ${end_time}`
                  : "Pushim"
                }
              </p>
            </div>
          </div>
        </div>

        <div className="popular-services">
          <div className="left">
            <h5>{TranslateLabel("Shërbimet më të njohura:")}</h5>
          </div>
          <div className="services">
            {salon?.salon_sub_services?.filter(el => el.is_fav == true)?.length > 0 ?
              salon?.salon_sub_services?.filter(el => el.is_fav == true)?.map((treatment, index) => {
                return (
                  <div className="treatment-wrapper" key={index}>
                    <div className="treatment-info">
                      <h6>{props?.selected_lang === "AL" ? treatment.name_al : treatment.name_en}</h6>
                      <p>{treatment?.duration}</p>
                    </div>
                    <div>
                      <button onClick={() => handleChooseButton(treatment, salon?.id)} className="choose-btn">Zgjedh</button>
                    </div>

                  </div>
                )
              })
              :
              salon?.salon_sub_services?.slice(0, 5)?.map((treatment, index) => {
                return (
                  <div className="treatment-wrapper" key={index}>
                    <div className="treatment-info">
                      <h6>{props?.selected_lang === "AL" ? treatment.name_al : treatment.name_en}</h6>
                      <p>{treatment?.duration}</p>
                    </div>
                    <div>
                      <button onClick={() => handleChooseButton(treatment, salon?.id)} className="choose-btn">Zgjedh</button>
                    </div>

                  </div>
                )
              })
            }
          </div>
        </div>

        <div className="checkout-btn-wrapper">
          {/* {appointmentData && (
            <div className={`proceed-to-checkout ${isBtnActive ? "active" : ""}`}>
              <div className="proceed-to-checkout-btn" onClick={handleProceedToCheckoutBtn}>
                <RxReload />
              </div>
              <Link to="/checkout" className="wobbling-link">Proceed to Checkout <RxArrowRight /></Link>
            </div>
          )} */}
        </div>


        <div className="browse-services">
          <h5>Shfleto shërbimet:</h5>
          <div className="tabs-container">
            <Tabs selectedIndex={selectedTab ? selectedTab : 0} onSelect={(index) => { setSelectedTab(index) }}>
              <TabList>
                <Tab key="99999" onClick={() => { handleGetAllSalonServices(salon_id) }}>
                  <div className="tab-wrapper">
                    <div className="icon icon1"><CiGrid41 /></div>
                    <h5>Të gjitha</h5>
                  </div>
                </Tab>
                {allServices?.map((service, cindex) => {

                  return (

                    <Tab key={service.id} onClick={() => handleGetSingleService(service.id)}>
                      <div className="tab-wrapper">
                        <div className="icon service_img_source" style={{ backgroundImage: `url(${process.env.REACT_APP_API}/${service.img_url})` }}></div>
                        <h5 className="mb-0 text-center">{props?.selected_lang === "AL" ? service.name_al : service.name_en}</h5>
                      </div>
                    </Tab>
                  )
                }
                )}
              </TabList>

              {
                Array.isArray(salonSingleService?.subServices) &&
                <TabPanel key={salonSingleService?.service?.id}>
                  {salonSingleService?.subServices?.map((subService, index) => (
                    <>
                      <div className={`tab-panel-wrapper ${index > 0 && 'mt-0'}`}>
                        <div className={`tab-panel-left mb-lg-4 mb-md-4 mb-0 mt-lg-0 mt-md-0 mt-4`}>
                          <h5 className=" ">{props?.selected_lang === "AL" ? subService?.name_al : subService?.name_en}</h5>
                        </div>
                        <div className="tab-panel-right">
                          {subService?.sub_services?.map((service) => {
                            return (
                              <div key={service.id}>
                                <h4 className="mt-lg-4 mt-md-4 mt-0 mb-3">{props?.selected_lang === "AL" ? service?.name_al : service?.name_en}</h4>
                                {service?.salon_sub_services?.map((sub) => {
                                  return (
                                    <div className="treatment-wrapper" key={sub.id}>
                                      <div className="treatment-info">
                                        <h6>{props?.selected_lang === "AL" ? sub?.name_al : sub?.name_en}</h6>
                                        <p>{sub?.duration}</p>
                                      </div>
                                      <button onClick={() => handleChooseButton(sub, salon?.id)} className="choose-btn">Zgjedh</button>
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </>

                  ))}
                </TabPanel>
              }






              {allServices?.map((service) => (
                <TabPanel key={service.id}>
                  <div className="tab-panel-wrapper">
                    <div className="tab-panel-left">
                      {salonSingleService.service === service ? (
                        salonSingleService.subServices?.length > 0 ? (
                          <h5>{service?.name_al}</h5>
                        ) : (
                          <h5>Nuk kryejm keto sherbime</h5>
                        )
                      ) : null}
                    </div>
                    <div className="tab-panel-right">
                      {salonSingleService?.service === service ? (
                        salonSingleService?.subServices?.length > 0 ? (
                          salonSingleService?.subServices?.map((subService) => (

                            <div key={subService.id}>
                              {subService?.sub_services?.map((elm, index) => {
                                return (
                                  <>
                                    <h4 className={index > 0 ? "mt-4 mb-3" : ""}> {props?.selected_lang === "AL" ? elm?.name_al : elm?.name_en}</h4>
                                    {elm?.salon_sub_services?.map((el) => {
                                      return (
                                        <div className="treatment-wrapper" key={el.id}>
                                          <div className="treatment-info">
                                            <h6>{props?.selected_lang === "AL" ? el?.name_al : el?.name_en}</h6>
                                            <p>{el?.duration}</p>
                                          </div>
                                          <button onClick={() => handleChooseButton(el, salon?.id)} className="choose-btn">Zgjedh</button>
                                        </div>
                                      )
                                    })
                                    }
                                  </>

                                )
                              }
                              )}
                            </div>
                          ))
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>

        <div className="about-salon">
          <h5>{TranslateLabel("Rreth sallonit:")}</h5>
          <div className="location">
            <div className="map-container">
              <GoogleMap
                className="mapAbout"
                location={{ lat: Number(salon?.latitude), lng: Number(salon?.longitude) }}
                height="200px"
                width="100%"
              />
            </div>
            <div className="address">
              <h6>{salon?.salon?.name_al}</h6>
              <p><span>📍</span>{salon?.street_address_al}</p>
            </div>
          </div>
          <div className="salon-schedule">
            <div className="text">
              <p>
                {salon?.desc_al}
              </p>
            </div>

            <div className="schedule list-group">
              {sortedHours?.map((item, index) => {
                return (
                  <div className="schedule-info " key={index}>
                    <div className={`day ${item?.work_this_day == false ? "dayoff" : ""}`}>
                      <RxDotFilled />
                      <h6>{item.day}</h6>
                    </div>
                    <div className="time">
                      {
                        item?.work_this_day == false ?
                          <p className="dayoff">Mbyllur </p>
                          :
                          <p>{item.start_time.slice(11, 16)} -{item.end_time.slice(11, 16)} </p>
                      }


                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className=" row back">
            <p className="col-auto">Termini</p>
            <span className="col-auto"> {"->"} </span>
            <p className="col-auto"
            //  to={`/searchResult/searchData/all?location=${salon?.name_al}`}
            >Sallonet</p>
            <span className="col-auto"> {"->"} </span>
            <p className="col-auto">{salon?.name_al}</p>
          </div>
        </div>
      </div >

      {/* <div className="reviews-section">
        <h5 className="review-title">Reviews:</h5>
        <div className="reviews-info">
          <div className="left">
            <div className="review-info">
              <h1>{rating.toFixed(1)}</h1>
              <div className="block">
                <Rating
                  name="size-medium"
                  defaultValue={5}
                  size="medium"
                />
                <p>202 reviews</p>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="first">
              <div className="inline line">
                <h6>Ambienti</h6>
                <Rating name="size-medium" defaultValue={5} />
              </div>
              <div className="inline">
                <h6>Pastërtia</h6>
                <Rating name="size-medium" defaultValue={5} />
              </div>
            </div>
            <div className="second">
              <div className="inline line">
                <h6>Objekti</h6>
                <Rating name="size-medium" defaultValue={5} />
              </div>
              <div className="inline">
                <h6>Stafi</h6>
                <Rating name="size-medium" defaultValue={5} />
              </div>
            </div>
          </div>
        </div>
        <div className="the-team">
          <Tabs>
            <div className="left">
              <h5>Ekipi:</h5>
              <TabList>
                {salonWorkers?.map((el, index) => {
                  return (
                    // <></>
                    <Tab>
                      <div className="tab-wrapper" key={index}>
                        <div className="circle">{getInitials(el?.name)}</div>
                        <h6>{el?.name}</h6>
                      </div>
                    </Tab>
                  )
                })}
              </TabList>
            </div>
            <div className="right">
              <h5>Shërbimet:</h5>
              <TabPanel>
                <div className="tab-panel-wrapper">
                  <div className="icon icon1"></div>
                  <p>MakeUp</p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-panel-wrapper">
                  <div className="icon icon2"></div>
                  <p>Flokë</p>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="tab-panel-wrapper">
                  <div className="icon icon3"></div>
                  <p>Thonj</p>
                </div>
              </TabPanel>
            </div>
          </Tabs>
        </div>

        <p>Filtro në bazë të vlerësimit</p>

        <div className="about-salon">
          <h5>Rreth sallonit:</h5>
          <div className="location">
            <div className="map-container">
              <GoogleMap
                className="mapAbout"
                location={{
                  lat: Number(salon?.latitude), lng: Number(salon?.longitude)
                }}
                height="200px"
                width="100%"
              />
            </div>
            <div className="address">
              <h6>{salon?.name_al}</h6>
              <p><span>📍</span>{salon?.street_address_al}</p>
            </div>
          </div>
          <div className="salon-schedule">
            <div className="text">
              <p>
                {salon?.desc_al}
              </p>
            </div>

            <div className="schedule">
              {salonWorkingHours.map((item, index) => {
                console.log('item gashi',
                  item,
                  new Date(item.start_time).toLocaleTimeString([], opt))
                return (
                  <div className="schedule-info" key={index}>
                    <div className="day">
                      <RxDotFilled />
                      <h6>{item.day}</h6>
                    </div>
                    <div className="time">
                      <p>{new Date(item.start_time).toLocaleTimeString([], opt)} - {new Date(item.end_time).toLocaleTimeString([], opt)}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div> */}




    </div >
  )
};

const mapStateToProps = (state) => {
  return {
    appointments: state.data.appointments,
    selected_lang: state.data.selected_lang,
    lang_data: state.data.lang_data,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAppointment: (treatment, salonId) => dispatch(addAppointment({ ...treatment, salonId })),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SalonPage);

