import React, { useEffect, useState } from "react";
import './checkout.scss'
import { CheckoutButton } from "../../components/ui/buttonGroup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { addAppointmentData, setLoggedIn, setUserToken, setUserId } from "../../redux/Functions/actions";
import axios from "../../axios";
import { toast } from "react-toastify";
import sign from "jwt-encode";
import jwtDecode from "jwt-decode";

const Checkout2 = (props) => {
    const navigate = useNavigate()

    const { appointments } = props

    const [salonData, setSalonData] = useState()
    const [userData, setUserData] = useState()
    function getUserData() {

        axios.get(`api/client/users/single/${props?.user_id}`)
            .then(response => {
                setUserData(response?.data)
            })
            .catch(err =>
                console.log("Error fetching data")
            )
    }
    useEffect(() => {
        getUserData()
    }, [])

    const [name, setName] = useState(userData?.name || "")
    const [lastname, setLastname] = useState(userData?.lastname || "")
    const [email, setEmail] = useState(userData?.email || "")
    const [username, setUsername] = useState(userData?.username || "")
    const [password, setPassword] = useState(userData?.password || "")
    const [phone, setPhone] = useState(userData?.phone_number || "")
    const [note, setNote] = useState("")
    const [goalImage, setGoalImage] = useState()
    const [shouldSetAppt, setShouldSetAppt] = useState(true)


    const getSalonData = (data) => {
        axios.get(`/api/client/salon/single/${appointments[0]?.salon_id}`)
            .then(res => {
                setSalonData(res.data)
            })
            .catch(err => {
                toast.error(err?.msg)
                console.log("error", err)
            })

    };
    function checkImageType(file) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (file && allowedTypes.includes(file.type)) {
            setGoalImage(file ? file : null)
        } else {
            toast.info("Formatet e fotografive që pranohen janë .png, .jpg dhe .jpeg");
            setGoalImage(null)
            return false;
        }
    }

    useEffect(() => {
        getSalonData()
    }, [])
    // function addMinutesToTime(time, minutesToAdd) {
    //     const minutesToAddNumber = minutesToAdd.split(':').map(Number);
    //     const [hours, minutes, seconds] = time.split(':').map(Number);

    //     console.log('hours, minutes', hours, minutes + minutesToAddNumber)
    //     let updatedMinutes = minutes + minutesToAdd;
    //     let updatedHours = hours;

    //     if (updatedMinutes >= 60) {
    //         updatedHours += Math.floor(updatedMinutes / 60);
    //         updatedMinutes = updatedMinutes % 60;
    //     }

    //     const formattedTime = `${String(updatedHours).padStart(2, '0')}:${String(updatedMinutes).padStart(2, '0')}`;
    //     return formattedTime;
    // }
    let apptDateData = String(appointments[0]?.additionalData?.selectedDate)?.split('-')
    let apptYear = String(apptDateData[0])
    let apptMonth = String(apptDateData[1]).padStart(2, '0')
    let apptDate = String(apptDateData[2]).padStart(2, '0')
    const testData = `${apptYear}-${apptMonth}-${apptDate}`

    const selectedTIme = appointments[0]?.additionalData?.selectedTime
    const [hoursStart, minuteStart, secondsStart] = selectedTIme?.split(':')?.map(Number);


    const duration = props?.appointments[0]?.duration
    const [hoursEnd, minuteEnd, secondsEnd] = duration?.split(':')?.map(Number);



    let totalSeconds = secondsStart + secondsEnd;
    let totalMinutes = minuteStart + minuteEnd + Math.floor(totalSeconds / 60);
    let totalHours = hoursStart + hoursEnd + Math.floor(totalMinutes / 60);

    const seconds = "00";
    const minutes = totalMinutes % 60;
    const hours = totalHours % 24;
    const appointmentEnd = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`




    const postAppointment = () => {
        // console.log("props?.user_id", props?.user_id)
        if (goalImage && goalImage.size > 5 * 1024 * 1024) {
            toast.error("Fotografia nuk duhet të jetë më e madhe se 5 MB");
            setGoalImage(null)
            setShouldSetAppt(true);
            return;
        }
        else if (props?.user_id === null || props?.user_id === undefined) {
            const secretKey = process.env.REACT_APP_SECRET_KEY
            // alert("1")
            const body = {
                role_id: 5,
                name: name,
                surname: lastname,
                email: email,
                username: username,
                phone_number: phone,
                password: sign(password, secretKey)
            }
            axios
                .post("/api/auth/signup", body)
                .then(async (response) => {
                    if (response.status === 200) {
                        // toast.success("Regjistri u krye me sukses!");
                        // alert("11")
                        try {
                            const body = {
                                email: response?.data?.newUserData?.email,
                                password: sign(password, secretKey)
                            }
                            await axios
                                .post("/api/auth/login", body)
                                .then((logedIn) => {
                                    if (logedIn.status === 200) {

                                        // alert("2")

                                        const decodeToken = jwtDecode(logedIn.data.token, secretKey)
                                        props.setLoggedIn(true)
                                        window.localStorage.setItem('termini_im', logedIn.data.token, { expires: 1 });
                                        props?.setUserToken(logedIn.data.token)
                                        props.setUserId(decodeToken.user_id)
                                        props.setLoggedIn(true);
                                        toast.success("Jeni kyqur me sukses!");
                                        const formData = new FormData();

                                        formData.append('appt_start', appointments[0]?.additionalData?.selectedTime);
                                        formData.append('appt_end', appointmentEnd);
                                        formData.append('costumer_id', decodeToken?.user_id || props?.user_id);
                                        formData.append('salon_id', appointments[0]?.salon_id);
                                        formData.append('salon_address_id', appointments[0]?.salon_address_id);
                                        formData.append('salon_worker_id', appointments[0]?.additionalData?.workerId);
                                        formData.append('service_id', appointments[0]?.id);
                                        formData.append('date_of_appointment', testData);
                                        formData.append('created_by', decodeToken?.user_id || props?.user_id);
                                        formData.append('reserver_name', name == null || name == undefined ? userData?.name : name);
                                        formData.append('reserver_email', email == null || email == undefined ? userData?.email : email);
                                        formData.append('reserver_phone', phone == null || phone == undefined ? userData?.phone_number : phone);
                                        formData.append('reserver_note', note);
                                        formData.append('goal_image_path', goalImage);

                                        axios.post(`/api/client/appointments/newAppt`, formData).then((res) => {
                                            // console.log('res i appt', res);
                                            let message = res?.data?.msg;
                                            if (res.status === 401) {
                                                message = 'Sesioni juaj ka skaduar. Ju lutem kyquni perseri!';
                                            }
                                            // alert("22")
                                            toast.success(message);
                                            setShouldSetAppt(true);
                                            navigate('/checkout3', { state: { appointmentData: res.data, client_email: userData?.email || "email-in tuaj" } });

                                        })
                                            .catch((err) => {
                                                // console.log('err.data', err?.response?.data);
                                                let errorMessage = err?.response?.data?.error || err?.response?.data;
                                                if (err.response.status === 401) {
                                                    errorMessage = 'Sesioni juaj ka skaduar. Ju lutem kyquni perseri!';
                                                }
                                                toast.error(errorMessage);
                                                setShouldSetAppt(true);
                                            });

                                    }
                                })
                                .catch((error) => {
                                    toast.error("Autentifikimi dështoi!");
                                    console.log('error:', error);
                                });

                        } catch (err) {
                            toast.error(err)
                        }
                    }
                })
                .catch((error) => {
                    setShouldSetAppt(true);

                    if (error.response && error.response.data) {
                        toast.error(error.response.data);
                    } else {

                        toast.error("Ndodhi një gabim gjatë regjistrimit.");
                    }
                    console.log('error:', error);
                });
        }

        else {


            const formData = new FormData();

            formData.append('appt_start', appointments[0]?.additionalData?.selectedTime);
            formData.append('appt_end', appointmentEnd);
            formData.append('costumer_id', props?.user_id);
            formData.append('salon_id', appointments[0]?.salon_id);
            formData.append('salon_address_id', appointments[0]?.salon_address_id);
            formData.append('salon_worker_id', appointments[0]?.additionalData?.workerId);
            formData.append('service_id', appointments[0]?.id);
            formData.append('date_of_appointment', testData);
            formData.append('created_by', props?.user_id);
            formData.append('reserver_name', name == null || name == undefined ? userData?.name : name);
            formData.append('reserver_email', email == null || email == undefined ? userData?.email : email);
            formData.append('reserver_phone', phone == null || phone == undefined ? userData?.phone_number : phone);
            formData.append('reserver_note', note);
            formData.append('goal_image_path', goalImage);

            axios.post(`/api/client/appointments/newAppt`, formData).then((res) => {
                // console.log('res i appt', res);
                let message = res?.data?.msg;
                if (res.status === 401) {
                    message = 'Sesioni juaj ka skaduar. Ju lutem kyquni perseri!';
                }
                toast.success(message);
                setShouldSetAppt(true);
                navigate('/checkout3', { state: { appointmentData: res.data, client_email: userData?.email || "email-in tuaj" } });
            })
                .catch((err) => {
                    // console.log('err.data', err?.response?.data);
                    let errorMessage = err?.response?.data?.error || err?.response?.data;
                    if (err.response.status === 401) {
                        errorMessage = 'Sesioni juaj ka skaduar. Ju lutem kyquni perseri!';
                    }
                    toast.error(errorMessage);
                    setShouldSetAppt(true);
                });
        }
    }

    return (
        <div className="checkout2-layout">
            {/* <div className="top-link">
                <a href="/">Kyçu</a>
                <p>ose vazhdo caktimin e terminit si vizitor.</p>
            </div> */}
            <div className="checkout2-inner">
                <div className="basket-container p-4">
                    <h4 className="tracking-in-expand">Detajet për termin </h4>
                    <form onSubmit={(e) => { e.preventDefault(); setShouldSetAppt(false); postAppointment() }}>
                        {props?.user_id === null || props?.user_id === undefined ?
                            <>
                                <div className="input-container">
                                    <p>Emri</p>
                                    <input type="text" required defaultValue={userData?.name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className="input-container">
                                    <p>Mbiemri</p>
                                    <input type="text" required defaultValue={userData?.lastname} onChange={(e) => setLastname(e.target.value)} />
                                </div>
                            </>
                            : <div className="input-container">
                                <p>Emri i plotë</p>
                                <input type="text" required defaultValue={userData?.name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        }
                        {props?.user_id === null || props?.user_id === undefined ?
                            <>
                                <div className="input-container">
                                    <p>Email</p>
                                    <input type="email" required defaultValue={userData?.email} onChange={(e) => setEmail(e.target.value)} autocomplete="off" />
                                </div>
                                <div className="input-container">
                                    <p>Username</p>
                                    <input type="username" required onChange={(e) => setUsername(e.target.value)} autocomplete="off" />
                                </div>
                            </>
                            :
                            <div className="input-container">
                                <p>Email</p>
                                <input type="email" required defaultValue={userData?.email} onChange={(e) => setEmail(e.target.value)} autocomplete="off" />
                            </div>

                        }

                        {props?.user_id === null || props?.user_id === undefined &&

                            <>
                                <div className="input-container">
                                    <p>Fjalëkalimi</p>
                                    <input type="password" required defaultValue={userData?.password} onChange={(e) => setPassword(e.target.value)} autocomplete="off" />
                                </div>
                                <div className="input-container">
                                    <p>Konfirmo fjalëkalimin</p>
                                    <input type="password" required defaultValue={userData?.password} onChange={(e) => setPassword(e.target.value)} autocomplete="off" />
                                </div>
                            </>

                        }

                        <div className="input-container">
                            <p>Numri i telefonit</p>
                            <input type="number" required defaultValue={userData?.phone_number} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="input-container">
                            {/* <p>Si dëshironi qe rezultati i terminit te jete?</p>
                            <input type="file" onChange={(e) => setGoalImage(e.target.files[0])} /> */}

                            <div class="d-flex align-items-center justify-content-center w-100 image_upload_container">
                                <label for="dropzone-file" class="d-flex flex-column align-items-center justify-content-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    {!goalImage && (
                                        <>
                                            <div class="d-flex flex-column align-items-center justify-content-center  pb-6">
                                                <svg class="col-2 w-8 h-8 my-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ngarko fotografinë se cilat janë rezultatet e </span> pritura të terminit</p>
                                                <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                                            </div>
                                            <input accept=".jpg, .jpeg, .png" id="dropzone-file" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                                        </>
                                    )}
                                    {goalImage && (
                                        <>
                                            <div class="d-flex flex-column align-items-center justify-content-center  pb-6">
                                                <img id="uploaded-image" className="img-fluid mt-3 col-8 " src={URL.createObjectURL(goalImage)} alt="Uploaded" />
                                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-weight-bold">Ndrysho fotografinë</span></p>
                                                <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG, JPEG (MAX. 5MB)</p>
                                            </div>
                                            <input id="dropzone-file" accept=".jpg, .jpeg, .png" type="file" class="visually-hidden" onChange={(e) => checkImageType(e?.target?.files[0])} />
                                        </>
                                    )}
                                </label>
                            </div>

                        </div>


                        <div className="input-container">
                            <p>Shënime për termin</p>
                            {/* <input type="text" onChange={(e) => setNote(e.target.value)} /> */}
                            <textarea
                                rows="4"
                                onChange={(e) => setNote(e.target.value)}
                            ></textarea>
                        </div>
                        <button disabled={shouldSetAppt !== true ? true : false} type="submit" style={{ backgroundColor: '#ff5758', borderRadius: '10px', color: 'white', padding: '3px 30px', border: 'none' }}>
                            <span style={{ fontSize: '18px' }}>Cakto terminin</span>
                        </button>
                        {/* <div className="checkout-btn d-flex justify-content-end mt-4">
                        </div> */}
                    </form>

                </div>

                <div className="basket-container order-content p-4">
                    <h4 className="tracking-in-expand">{salonData?.name_al}</h4>

                    <div className="dateAndTime">
                        <h1>{appointments[0]?.additionalData?.selectedTime.slice(0, 5)}</h1>
                        <div className="line"></div>
                        <div className="date">
                            <h6>{appointments[0]?.additionalData?.selectedDate}</h6>
                            <p>{appointments[0]?.duration.slice(0, 5)}</p>
                        </div>
                    </div>

                    <Link to="/checkout">Ndrysho orën</Link>

                    <div className="details">
                        <h5 className="text-center">{appointments[0]?.name_al}</h5>
                        <p className="text-center">Termini te {salonData?.name_al}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    // selected_lang: state.data.selected_lang,
    user_id: state.data.user_id,
    appointments: state.data.appointments,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedIn: (data) => dispatch(setLoggedIn(data)),
        addAppointmentData: (data) => dispatch(addAppointmentData(data)),
        setUserToken: (data) => dispatch(setUserToken(data)),
        setUserId: (data) => dispatch(setUserId(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout2);

// export default Checkout2;