import { SET_LOGGED_IN, LOGOUT, ADD_APPOINTMENT, REMOVE_APPOINTMENT, RESET_APPOINTMENTS, SET_USER_TOKEN, SET_USER_ID, ADD_APPOINTMENT_DATA, SET_SELECTED_LANG, SET_LANG_DATA } from "./types";


const INITIAL_STATE = {
  selected_lang: 'AL',
  lang_data: [],
  logged_in: false,
  username: "",
  appointments: [],
};

const initialReducer = (state = INITIAL_STATE, action) => {

  // console.log("action.type", action)
  switch (action.type) {
    case SET_SELECTED_LANG:
      return { ...state, selected_lang: action.data };
    case SET_LANG_DATA:
      return { ...state, lang_data: action.data };
    case SET_LOGGED_IN:
      return { ...state, logged_in: action.data };
    case SET_USER_TOKEN:
      return { ...state, user_token: action.data };
    case SET_USER_ID:
      return { ...state, user_id: action.data };
    case LOGOUT:
      return { ...state, logged_in: false, username: "", appointments: [] };
    // case SET_USER_NAME:
    //   return { ...state, username: action.data };
    case ADD_APPOINTMENT:
      return { ...state, appointments: [...state.appointments, action.payload], };
    case ADD_APPOINTMENT_DATA:
      if (state.appointments.length > 0) {
        const updatedFirstAppointment = {
          ...state.appointments[0],
          additionalData: {
            workerId: action.payload.workerId,
            selectedDate: action.payload.selectedDate,
            selectedTime: action.payload.selectedTime,
          },
        };

        return {
          ...state,
          appointments: [updatedFirstAppointment, ...state.appointments.slice(1)],
        };
      }
      return state;

    case REMOVE_APPOINTMENT:
      const updatedAppointments = state.appointments.filter((_, index) => index !== action.payload);
      return {
        ...state,
        appointments: updatedAppointments,
      };
    case RESET_APPOINTMENTS:
      return {
        ...state,
        appointments: []
      };
    default:
      return state;
  }
};

export default initialReducer;
