import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container mt-4 mb-2">
            <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                    <h2 className='text-center '>Termat dhe Kushtet e Përdorimit për "Termini"</h2>
                    <p className='mx-auto text-justify'>
                        "Termini" është një platformë inovative e krijuar për të digjitalizuar sallonet e bukurisë dhe për t'u përqendruar në një eksperiencë më efikase dhe të personalizuar për klientët dhe bizneset e bukurisë.
                    </p>

                    <h4 className='fw-bolder mt-3'>Përmbledhje:</h4>
                    <p>
                        Ju lutemi të lexoni me kujdes termat dhe kushtet e përdorimit për sallonet e "Termini" para se të përdorni shërbimet tona.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}

                    <h4 className='fw-bolder mt-3'>1. Përmbajtja e Postuar nga Përdoruesit</h4>
                    <p>
                        "Termini" nuk ka mundësi të monitorojë çdo veprim të përdoruesve në platformën e tij dhe, si rrjedhojë, nuk merr përgjegjësi për materiale të postuara nga përdoruesit. Nëse materialet ose profili juaj përballet me cënim, ju lutemi të na njoftoni.
                    </p>

                    <p>
                        Përmbajtja e postuar nga përdoruesit, përfshirë atë që mund të jetë e lidhur me të drejta të autorit ose pronë intelektuale të të tjerëve, është e vetë-publikuar dhe "Termini" nuk do të mbajë përgjegjësi nëse kjo përmbajtje shkel të drejtat e palëve të treta. Ju, si përdorues, do të keni përgjegjësi të plotë për përmbajtjen që postoni.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}

                    <h4 className='fw-bolder mt-3'>2. E Drejta e Plotë Juridike</h4>
                    <p>
                        "Termini" është një platformë online e dizajnuar për digjitalizimin e salloneve të bukurisë, duke ofruar një eksperiencë më efikase dhe të personalizuar për klientët dhe bizneset e bukurisë. Qëllimi kryesor i "Termini" është të ofrojë një eksperiencë më efikase dhe të personalizuar për klientët dhe bizneset e bukurisë duke përdorur një platformë online për digitalizimin e salloneve të bukurisë.
                    </p>

                    <p>
                        Sipas kushteve dhe rregullave, një marrëveshje e ndërsjellë ekziston midis përdoruesit (ju) dhe "Termini", me seli në Rr.____________. Kjo përfshin uebfaqen e tyre në www.termini.beauty.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}

                    <h4 className='fw-bolder mt-3'>3. Materialet që Ju Lejohen të Postoni</h4>
                    <p>
                        Ju keni lirinë të postoni çdo material që prezanton më së miri veprimtarinë tuaj, duke përfshirë imazhe, mesazhe, etj., në "Termini". Megjithatë, për të siguruar një ambient të respektueshëm dhe profesional, ju lutemi të respektoni kufizimet e përcaktuara.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                </div>
                <div className="col-12 col-md-10">
                    <hr className='mb-3 mt-4' />
                    <h2 className='text-center mb-3 '>E drejta e plotë juridike</h2>
                    <p className='mx-auto text-justify'>
                        "Termini" është një platformë online e dizajnuar për digjitalizimin e salloneve të bukurisë, duke ofruar një eksperiencë më efikase dhe të personalizuar për klientët dhe bizneset e bukurisë. Qëllimi kryesor i “Termini” është të ofrojë një eksperiencë më efikase dhe të personalizuar për klientët dhe bizneset e bukurisë duke përdorur një platformë online për digitalizimin e salloneve të bukurisë.
                    </p>

                    <p>
                        Sipas kushteve dhe rregullave, një marrëveshje e ndërsjellë ekziston midis përdoruesit (ju) dhe "Termini", me seli në Rr.____________. Kjo përfshin uebfaqen e tyre në <a href="www.termini.beauty" target='_blank'>www.termini.beauty </a>.
                    </p>

                    <p>
                        Kushtet dhe rregullat aplikohen çdo herë që përdorni shërbimet e "Termini", kështu që ju lutemi të lexoni ato me kujdes para se të vendosni të bëheni pjesë e komunitetit të “Termini”.
                    </p>
                    <hr />
                    <h4 className='fw-bolder mt-3'>1. Materialet që ju lejohen të postoni në uebfaqen e ''Termini''</h4>
                    <p>
                        Ju keni lirinë të postoni çdo material që prezanton më së miri veprimtarinë tuaj, duke përfshirë imazhe, mesazhe, etj., në "Termini". Megjithatë, për të siguruar një ambient të respektueshëm dhe profesional, ju lutemi të respektoni këto kufizime:
                    </p>
                    <p>
                        1. Respektimi i gjuhës dhe sjelljes fyese;
                    </p>
                    <p>
                        2.	Kufizimi i referencave tregtare dhe reklamave;
                    </p>
                    <p>
                        3.	Mospostimi i detajeve të kontaktit publikisht përmes email/telefonit;
                    </p>
                    <p>
                        4.	Parandalimi i postimit të materialeve që mund të përmbajnë viruse ose programe të dizajnuara për të dëmtuar platformën;
                    </p>
                    <p>
                        5.	Mospromovimi i materialeve që shkelin të drejtat e palëve të treta, përfshirë të drejtat intelektuale dhe private;
                    </p>
                    <p>
                        6.	Mosatribuimi i materialeve një personi tjetër pa pëlqimin e tij/saj.
                    </p>
                    <p>Këto udhëzime janë themelore për krijimin e një mjedisi të sigurt dhe të përshtatshëm për të gjithë përdoruesit në "Termini". Shkelja e këtyre kufizimeve mund të rezultojë në heqjen e materialeve dhe ndëshkimin e përdoruesit.</p>

                    <hr />

                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                    {/* Shto më shumë seksione sipas nevojave tuaja */}


                    <h4 className='fw-bolder mt-3'>2. Politikat e privatësisë</h4>
                    <p className='text-justify'>
                        Ju nuk duhet të ndani asnjë material që ka të bëjë me informacionin tuaj personal/bankar në faqen tuaj të profilit (“Profili”) siç janë adresat e shtëpisë ose kodet postare, numrat e telefonit, adresat e email, URL, kartat e kreditit/debitit ose informacione të tjera bankare. Nëse vendosni të publikoni informacione personale për veten ose të tjerët, ju do të jeni përgjigjës në rast të shkeljes së privatësisë. Ne ju inkurajojmë të jeni të kujdesshëm kur shpërndani të dhënat tuaja personale me palët e treta në internet.                    </p>
                    <p>
                        Ju mund të përdorni informacionin e shpërndarë nga përdorues të tjerë në shërbimet e “Termini” nëse një veprim i tillë është në përputhje me termat dhe kushtet e përshkruara më lart. Duke u thënë kjo, ju nuk mund të përdorni të dhënat e dikujt tjetër për përdorim komercial, për të dëmtuar, ngacmuar ose për të kryer akte të paligjshme. “Termini” ruan të drejtën për të mbyllur llogarinë tuaj, nëse ju keqpërdorni detajet dhe materialet në të.
                    </p>

                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                    <hr />
                    <h4 className='fw-bolder mt-3'>3. Materialet e publikuara në ''Termini''</h4>
                    <p>
                        Kur publikoni materiale në “Termini”, ato mund të shihen nga publiku i gjerë. Nëse nuk dëshironi që materiali të shihet nga të tjerët, ne rezervojmë të drejtën (pa asnjë detyrim) pa njoftuar, për të ndryshuar ose hequr, kufizuar ose bllokuar për ju, aksesin në çdo lloj materiali që ju publikoni në “Termini” pa mbajtur përgjegjësi. Ne nuk jemi të detyruar të tregojmë çfarëdo që ju publikoni në “Termini”, as të kontrollojmë vërtetësinë e materialit tuaj që postoni në “Termini”, as të monitorojmë përdorimin tuaj ose të anëtarëve të tjerë që përdorin “Termini”.
                    </p>
                    <hr />
                    <h4 className='fw-bolder mt-3'>4. Materialet e publikuara në ''Termini''</h4>
                    <p>
                        Nëse ne besojmë se ju keni shkelur parimet tona, “Termini” ruan të drejtën të çaktivizojë profilin tuaj në çdo kohë pa ndonjë detyrim ose domosdoshmëri që të ju njoftojë paraprakisht dhe as t’ju rimbursojë për ndonjë shërbim të papërdorur.
                        “Termini” ka të drejtë të pezullojë ose çaktivizojë regjistrimin dhe të drejtën tuaj për akses dhe/ose përdorim të shërbimeve të “Termini”.
                    </p>
                    <p>
                        “Termini” përdor teknologji të avancuar për të identifikuar sjelljen e paligjshme dhe ka të drejtë të bllokojë ose kufizojë ndonjë adresë IP specifike.
                        Në përgjithësi, “Termini” do të përpiqet (nuk është i detyruar) t’ju informojë nëse aksesi dhe përdorimi i profilit në “Termini” është pezulluar ose do të përfundojë. Kjo përcakton një pjesë të rëndësishme të marrëveshjes mes “Termini” dhe Sallonit, për të siguruar transparencë dhe kujdes ndaj përdoruesve tanë.
                    </p>
                    <hr />
                    <h4 className='fw-bolder mt-3'>5. Përfundimi i përdorimit të platformës ‘Termini’ nga ana juaj</h4>
                    <p>
                        Nëse jeni regjistruar dhe keni përdorur shërbimet e “Termini”, ju mund ta ndërpresni regjistrimin tuaj në çdo kohë, duke shkuar te _________; në “Termini” dhe zgjidhni _______;Fshini llogarinë time _______&_______;. Për arsye të privatësisë, “Termini” do të përfundojë të gjitha llogaritë e fshira, prandaj, nuk mund të rikuperoni llogarinë tuaj në një kohë të mëvonshme. Për detaje të mëtejshme, ju lutemi referojuni Politikës së Privatësisë. Në përgjithësi, materiali që keni ngarkuar ose shpërndarë në “Termini” nuk do të jetë më i dukshëm pas përfundimit ose anulimit të llogarisë.
                    </p>
                    <p>
                        Duke përdorur shërbimet e “Termini”, ju pranoni dhe pajtoheni me rregullat dhe kushtet e paraqitura më poshtë:
                    </p>
                    <h5 className='fw-bolder mt-3'>A)   Përdorimi i Platformës:</h5>
                    <p>
                        Ju lejohet të përdorni platformën "Termini" në përputhje me qëllimin e saj kryesor, për shërbimet dhe nevojat e biznesit tuaj në fushën e bukurisë.
                    </p>
                    <h5 className='fw-bolder mt-3'>B)	Informacioni Personal:</h5>
                    <p>
                        “Termini” respekton privatësinë tuaj. Informacioni personal që jepni në platformë përdoret vetëm për qëllime të nevojshme për shërbimet tona dhe është mbrojtur nga masat e sigurisë të përshtatura.
                    </p>
                    <h5 className='fw-bolder mt-3'>C)	 Përgjegjësia për Fjalëkalimet:</h5>
                    <p>
                        Ju jeni përgjegjës për ruajtjen e sigurisë së fjalëkalimeve dhe të dhënave të llogarisë suaj. Në rast të ndonjë dyshimi për shfrytëzim të paautorizuar, ju lutemi të njoftoni menjëherë "Termini"-n. Kjo përgjegjësi personale ndihmon në ruajtjen e sigurisë së llogarisë suaj dhe përmirëson përvojën tuaj në platformë.
                    </p>
                    <h5 className='fw-bolder mt-3'>D)	Lidhjet dhe Konfliktet:</h5>
                    <p>
                        Çdo mosmarrëveshje apo konflikt midis ju dhe "Termini" do të zgjidhet përmes procedurave të ndërmjetësimit, duke respektuar ligjet dhe rregulloret e Republikës së Kosovës.
                    </p>
                    <h5 className='fw-bolder mt-3'>E)	Aktiviteti i Biznesit në ARBK:</h5>
                    <p>
                        Çdo biznes që regjistrohet në "Termini" duhet të ketë një status të hapur dhe aktiv në Autoritetin e Regjistrimit të Biznesit në Kosovë (ARBK). Mosrespektimi i këtij rregulli mund të çojë në pezullimin ose mbylljen e llogarisë së biznesit në platformë.
                    </p>
                    <h5 className='fw-bolder mt-3'>F)	Detyrimet dhe Përgjegjësitë në Ofrimin e Shërbimeve nga Sallonet</h5>
                    <p>
                        Salloni (ose 'biznesi') është i vetmi përgjegjës për cilësinë e shërbimeve të ofruara. Përdoruesit janë të inkurajuar të komunikojnë drejtpërdrejt me sallonin për çdo pyetje apo shqetësim dhe të bëjnë zgjedhje të informuara. Inkurajimi i këtij komunikimi drejtëpërdrejt ndihmon përdoruesit të bëjnë zgjedhje të informuara dhe të sigurohen për cilësinë dhe kënaqësinë e shërbimeve të ofruara nga salloni.

                        Ju lutemi të lexoni këto terma dhe kushte me kujdes para se të përdorni shërbimet tona në platformën "Termini". Përdorimi i platformës konsiderohet si pranim i këtyre termave dhe kushteve. “Termini” rezervon të drejtën për të ndryshuar ose përditësuar këto kushte në çdo kohë.
                    </p>
                    <p className='fst-italic text-center mt-4'>Përditësuar për herë të fundit më 15 Dhjetor 2023.</p>
                    {/* Shto më shumë seksione sipas nevojave tuaja */}
                </div>



            </div>
        </div>
    );
};

export default TermsAndConditions;
