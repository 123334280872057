export const SET_SELECTED_LANG = "SET_SELECTED_LANG";
export const SET_LANG_DATA = "SET_LANG_DATA";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_NAME = "SET_USER_NAME";

export const LOGOUT = "LOGOUT";
// export const SET_USER_NAME = "SET_USER_NAME";
export const ADD_APPOINTMENT = 'ADD_APPOINTMENT';
export const ADD_APPOINTMENT_DATA = 'ADD_APPOINTMENT_DATA';
export const REMOVE_APPOINTMENT = 'REMOVE_APPOINTMENT';

export const RESET_APPOINTMENTS = 'RESET_APPOINTMENTS';
